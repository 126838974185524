const SET_CONNECTED_CONTRACT = 'SET_CONNECTED_CONTRACT';
const SET_IS_SEGUROS_CONTRACT = 'SET_IS_SEGUROS_CONTRACT';

export default {
	namespaced: true,

	state() {
		return {
			connectedContract: null,
			isSegurosContract: false,
		};
	},
	mutations: {
		[SET_CONNECTED_CONTRACT](state, value) {
			state.connectedContract = value;
		},
		[SET_IS_SEGUROS_CONTRACT](state, value) {
			state.isSegurosContract = value;
		},
	},

	actions: {
		reset({ commit }) {
			commit(SET_CONNECTED_CONTRACT, null);
		},

		get({ dispatch }) {
			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url: '/contracts',
							method: 'GET',
						},
					},
				},
				{ root: true }
			)
				.then(({ data }) => data)
				.catch(/* istanbul ignore next */ (err) => err);
		},

		set({ commit, dispatch, state }, contract) {
			const { connectedContract } = state;

			if (connectedContract?.id === contract?.id) {
				return;
			}

			const { id, description } = contract;
			const lowerCaseDescription = description?.toLowerCase();

			/* istanbul ignore else */
			if (lowerCaseDescription) {
				commit(SET_IS_SEGUROS_CONTRACT, lowerCaseDescription === 'seguros');
			}

			const url = `/contracts/${id}`;
			const method = 'PATCH';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
				},
				{ root: true }
			)
				.then(({ data }) => {
					dispatch('profiles/get', null, { root: true });

					commit(SET_CONNECTED_CONTRACT, data);

					return data;
				})
				.catch((error) => Promise.reject(error));
		},

		getInterveners({ dispatch }, idContract) {
			const url = `/contracts/${idContract}/interveners`;
			const method = 'GET';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
				},
				{ root: true }
			)
				.then(({ data }) => data)
				.catch(() => {});
		},
	},
};

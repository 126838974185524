import SessionCache from '@plugins/cache/index';

const cache = new SessionCache('google-pay');

export default {
	namespaced: true,

	actions: {
		getCardEligibility({ dispatch }, { productId, productNumber }) {
			const url = `/products/${productId}/elegibility`;
			const method = 'GET';
			const cacheKey = `${productNumber}/isCardEligible`;

			if (cache.has(cacheKey)) {
				return cache.get(cacheKey);
			}

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
				},
				{ root: true }
			)
				.then(({ data }) => {
					cache.set(cacheKey, {
						isCardEligible: data?.active,
						cardHolderName: data?.cardholderName,
					});

					return cache.get(cacheKey);
				})
				.catch((err) => Promise.reject(err));
		},

		getCardState(store, cardId) {
			const cacheKey = `${cardId}/isCardAdded`;

			if (cache.has(cacheKey)) {
				return cache.get(cacheKey);
			}

			return new Promise((resolve, reject) => {
				const channel = new MessageChannel();
				const requestTimer = setTimeout(() => {
					reject(new Error('TIMEOUT'));
				}, 10000);

				channel.port1.onmessage = ({ data }) => {
					clearTimeout(requestTimer);

					const isSuccess = data?.status === 200;
					const isCardAdded = data?.isCardAdded;

					if (isSuccess) {
						cache.set(cacheKey, { isCardAdded });
						resolve(data);
					} else {
						reject(data);
					}
				};

				setTimeout(() => {
					window.postMessage(
						{
							name: 'native-get-wallet-state',
							detail: { cardId },
						},
						'*',
						[channel.port2]
					);
				}, 400);
			});
		},

		validateGooglePayOperation({ dispatch }, { productId }) {
			const url = `/products/${productId}/xpay-validate`;
			const method = 'POST';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
				},
				{ root: true }
			);
		},

		getWalletData(store, { addressData, cardId }) {
			const cardDataKey = `${cardId}/isCardEligible`;
			const walletDataKey = `${cardId}/walletData`;
			const cardData = cache.get(cardDataKey);

			const detail = {
				addressData,
				cardHolderName: cardData?.cardHolderName,
				active: cardData?.isCardEligible,
			};

			if (cache.has(walletDataKey)) {
				return cache.get(walletDataKey);
			}

			return new Promise((resolve, reject) => {
				const channel = new MessageChannel();

				channel.port1.onmessage = ({ data }) => {
					const isSuccess = data?.status === 200;

					if (isSuccess) {
						cache.set(walletDataKey, {
							clientDeviceID: data?.clientDeviceID,
							clientWalletAccountID: data?.clientWalletAccountID,
						});
						resolve(data);
					} else {
						reject(data);
					}
				};

				setTimeout(() => {
					window.postMessage(
						{
							name: 'native-get-wallet-data',
							detail,
						},
						'*',
						[channel.port2]
					);
				}, 400);
			});
		},

		startCardProvisioning({ dispatch }, { productId, cardId }) {
			const url = `/products/${productId}/provision`;
			const method = 'POST';
			const walletDataKey = `${cardId}/walletData`;
			const walletData = cache.get(walletDataKey);

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
					payload: {
						application: 'Google Pay',
						clientAppID: 'es-grupocaminos-bancocaminos',
						clientDeviceID: walletData.clientDeviceID,
						clientWalletAccountID: walletData.clientWalletAccountID,
					},
				},
				{ root: true }
			).then(({ data }) => data);
		},

		addCardToWallet(store, { detail = {}, cardId }) {
			const cacheKey = `${cardId}/isCardAdded`;

			return new Promise((resolve, reject) => {
				const channel = new MessageChannel();

				channel.port1.onmessage = ({ data }) => {
					const isSuccess = data?.status === 200;

					if (isSuccess) {
						cache.set(cacheKey, { isCardAdded: true });
						resolve(data);
					} else {
						reject(data);
					}
				};

				setTimeout(() => {
					window.postMessage(
						{
							name: 'native-add-card-to-wallet',
							detail,
						},
						'*',
						[channel.port2]
					);
				}, 400);
			});
		},

		/* istanbul ignore next */
		clearCache(store, cardId) {
			const cacheKey = `${cardId}/isCardAdded`;

			if (cache.has(cacheKey)) {
				cache.clear(cacheKey);
			}
		},
	},
};

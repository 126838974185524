const SET_USER_SESSION = 'SET_USER_SESSION';
const SET_USER_GREETED = 'SET_USER_GREETED';
const REMOVE_USER_SESSION = 'REMOVE_USER_SESSION';
const SET_THEME = 'SET_THEME';
const SET_LANG = 'SET_LANG';
const SET_REMEMBER_TOKEN = 'SET_REMEMBER_TOKEN';
const REMOVE_REMEMBER_TOKEN = 'REMOVE_REMEMBER_TOKEN';
const SET_IS_NEWS_OMITTED = 'SET_IS_NEWS_OMITTED';
const SET_IS_MINOR = 'SET_IS_MINOR';
const SET_VERTICAL_COLLECTIVE = 'SET_VERTICAL_COLLECTIVE';

const USER_SESSION_KEY = 'userSession';
const THEME_SESSION_KEY = 'themeSession';
const LANG_SESSION_KEY = 'langSession';
const NEWS_SESSION_KEY = 'newsSession';

export default {
	namespaced: true,

	state() {
		const userSession =
			JSON.parse(localStorage.getItem(USER_SESSION_KEY)) || {};
		const themeSession =
			JSON.parse(localStorage.getItem(THEME_SESSION_KEY)) || {};
		const langSession =
			JSON.parse(localStorage.getItem(LANG_SESSION_KEY)) || {};
		const newsSession = localStorage.getItem(NEWS_SESSION_KEY) || '';

		const {
			userName = '',
			lastLogin = null,
			rememberToken = '',
		} = userSession;
		const { theme = 'light' } = themeSession;
		const { lang = 'es' } = langSession;
		const isNewsOmitted = newsSession;

		return {
			userGreeted: false,
			userName,
			lastLogin,
			rememberToken,
			theme,
			lang,
			isNewsOmitted,
			isMinor: false,
			userId: null,
			vertical: 0,
			collective: 0,
		};
	},

	mutations: {
		[SET_USER_SESSION](state, data) {
			state.userName = data.userName;
			state.lastLogin = data.lastLogin;
			state.userId = data?.userId;
		},

		[SET_REMEMBER_TOKEN](state, data) {
			state.rememberToken = data;
		},

		[REMOVE_USER_SESSION](state) {
			state.userName = '';
			state.lastLogin = null;
		},

		[REMOVE_REMEMBER_TOKEN](state) {
			state.rememberToken = null;
		},

		[SET_USER_GREETED](state, value) {
			state.userGreeted = value;
		},

		[SET_THEME](state, value) {
			state.theme = value;
		},

		[SET_LANG](state, value) {
			state.lang = value;
		},

		[SET_IS_MINOR](state, value) {
			state.isMinor = value;
		},

		[SET_IS_NEWS_OMITTED](state, value) {
			state.isNewsOmitted = value;
		},

		[SET_VERTICAL_COLLECTIVE](state, { vertical, collective }) {
			state.vertical = vertical;
			state.collective = collective;
		},
	},

	actions: {
		setVertical({ commit }, { vertical, collective }) {
			commit(SET_VERTICAL_COLLECTIVE, { vertical, collective });
		},

		setUserRole({ commit }, isMinor) {
			commit(SET_IS_MINOR, isMinor);
		},

		setUserSession({ commit }, sessionData) {
			commit(SET_USER_SESSION, sessionData);
			commit(SET_USER_GREETED, false);
		},

		rememberUserSession({ commit }, { userName, lastLogin, rememberToken }) {
			commit(SET_USER_SESSION, {
				userName,
				lastLogin,
			});
			commit(SET_REMEMBER_TOKEN, rememberToken);

			localStorage.setItem(
				USER_SESSION_KEY,
				JSON.stringify({
					userName,
					lastLogin,
					rememberToken,
				})
			);
		},

		removeUserSession({ commit }) {
			commit(REMOVE_USER_SESSION);
			commit(REMOVE_REMEMBER_TOKEN);
			sessionStorage.removeItem('SECURE_SESSION');
		},

		/* istanbul ignore next */
		forgetUserSession() {
			localStorage.removeItem(USER_SESSION_KEY);
		},

		/* istanbul ignore next */
		loadUserSession({ commit }) {
			const userSession =
				JSON.parse(localStorage.getItem(USER_SESSION_KEY)) || {};
			const {
				userName = '',
				lastLogin = null,
				rememberToken = '',
			} = userSession;

			commit(SET_USER_SESSION, {
				userName,
				lastLogin,
			});
			commit(SET_REMEMBER_TOKEN, rememberToken);
		},

		markUserGreeted({ commit }) {
			commit(SET_USER_GREETED, true);
		},

		setAppTheme({ commit }, theme) {
			commit(SET_THEME, theme);
			localStorage.setItem(THEME_SESSION_KEY, JSON.stringify({ theme }));
		},

		changeLanguage({ commit, dispatch }, { lang }) {
			commit(SET_LANG, lang);
			localStorage.setItem(LANG_SESSION_KEY, JSON.stringify({ lang }));

			// To notify backend the language change so that if any documents are downloaded these will be translated.
			const method = 'PATCH';
			const url = `/current/user/language`;

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
					payload: { language: lang },
				},
				{ root: true }
			);
		},

		skipNews({ commit }, version) {
			commit(SET_IS_NEWS_OMITTED, version);
			localStorage.setItem(NEWS_SESSION_KEY, version);
		},

		deleteSession({ dispatch }) {
			const method = 'DELETE';
			const url = '/session';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
				},
				{ root: true }
			);
		},

		deleteCache({ dispatch }) {
			const method = 'DELETE';
			const url = '/cache';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
				},
				{ root: true }
			);
		},
	},
};

import applePay from '@modules/apple-pay/m-apple-pay';
import authn from '@modules/authn/m-authn';
import bizum from '@modules/bizum/m-bizum';
import bugsnag from '@modules/bugsnag/m-bugsnag';
import cashflow from '@modules/cashflow/m-cashflow';
import communications from '@modules/communications/m-communications';
import notificationRestrictions from '@modules/notification-restrictions/m-notification-restrictions';
import contracts from '@modules/contracts/m-contracts';
import correosCash from '@modules/correos-cash/m-correos-cash';
import device from '@modules/device/m-device';
import googlePay from '@modules/google-pay/m-google-pay';
import investment from '@modules/investment/m-investment';
import liveagent from '@modules/liveagent/m-liveagent';
import loading from '@modules/loading/m-loading';
import marketplace from '@modules/marketplace/m-marketplace';
import modal from '@modules/modal/m-modal';
import moveMoney from '@modules/move-money/m-move-money';
import notification from '@modules/notification/m-notification';
import ontime from '@modules/ontime/m-ontime';
import otp from '@modules/otp/m-otp';
import products from '@modules/products/m-products';
import profiles from '@modules/profiles/m-profiles';
import preferences from '@modules/preferences/m-preferences';
import resources from '@modules/resources/m-resources';
import repayments from '@modules/products/m-repayments';
import secure from '@modules/secure/m-secure';
import service from '@modules/service/m-service';
import session from '@modules/session/m-session';
import signatures from '@modules/signatures/m-signatures';
import sirvase from '@modules/sirvase/m-sirvase';
import user from '@modules/user/m-user';
import app from './app/m-app';

export default {
	app,
	'apple-pay': applePay,
	authn,
	bizum,
	bugsnag,
	cashflow,
	communications,
	contracts,
	'correos-cash': correosCash,
	device,
	'google-pay': googlePay,
	investment,
	liveagent,
	loading,
	marketplace,
	'modal': modal(),
	'move-money': moveMoney,
	'notification': notification({
		name: 'notification',
		timeout: 4000,
	}),
	ontime,
	otp,
	products,
	profiles,
	preferences,
	resources,
	repayments,
	secure,
	service,
	session,
	signatures,
	sirvase,
	user,
	notificationRestrictions,
};

export default {
	namespaced: true,

	actions: {
		get({ dispatch }, productId) {
			const url = `/products/${productId}/repayments`;
			const method = 'GET';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
				},
				{ root: true }
			)
				.then(({ data }) => JSON.parse(JSON.stringify(data)))
				.catch(() => {});
		},
	},
};

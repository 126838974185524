import Vue from 'vue';
import Vuex from 'vuex';
import modules from './modules';
import { privateCache } from '@plugins/cache/cache';

function cacheRegisteredModules() {
	Object.keys(modules).forEach((key) => {
		privateCache.set(key, new Map());
	});
}

const cachePlugin = (store) => {
	store.watch(
		({ secure: { identity } }) => identity,
		() => cacheRegisteredModules()
	);
};

cacheRegisteredModules();

Vue.use(Vuex);

export default new Vuex.Store({
	modules,
	strict: process.env.NODE_ENV !== 'production',
	plugins: [cachePlugin],
});

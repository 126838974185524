const SET_TABLE_MODES = 'SET_TABLE_MODES';

export default {
	namespaced: true,

	state() {
		return {
			isProductTableMode: false,
			isMovementTableMode: false,
		};
	},

	mutations: {
		[SET_TABLE_MODES](state, { isProductTableMode, isMovementTableMode }) {
			Object.assign(state, {
				isProductTableMode,
				isMovementTableMode,
			});
		},
	},

	actions: {
		get({ dispatch, commit }) {
			const url = '/preferences';
			const method = 'GET';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
				},
				{ root: true }
			)
				.then(({ data }) => {
					commit(SET_TABLE_MODES, {
						isProductTableMode: data?.tableViewProd,
						isMovementTableMode: data?.tableViewMov,
					});
				})
				.catch(/* istanbul ignore next */ () => {});
		},

		update({ dispatch }, { isProductTableMode, isMovementTableMode }) {
			const url = '/preferences';
			const method = 'PATCH';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
					payload: {
						tableViewProd: isProductTableMode,
						tableViewMov: isMovementTableMode,
					},
				},
				{ root: true }
			).catch(/* istanbul ignore next */ () => {});
		},
	},
};

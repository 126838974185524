import { render, staticRenderFns } from "./m-no-internet.vue?vue&type=template&id=818d1ffa&scoped=true"
import script from "./m-no-internet.vue?vue&type=script&lang=js"
export * from "./m-no-internet.vue?vue&type=script&lang=js"
import style0 from "./m-no-internet.vue?vue&type=style&index=0&id=818d1ffa&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "818d1ffa",
  null
  
)

component.options.__file = "m-no-internet.vue"
export default component.exports
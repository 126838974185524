import MLogout from '@modals/m-logout';
import {
	USER_INVALID_CRED,
	USER_NOT_FOUND,
	USER_WILL_BE_TEMP_BLOCKED,
	USER_WAS_TEMP_BLOCKED,
	USER_WILL_BE_PERMANENTLY_BLOCKED,
	REMEMBER_TOKEN_INVALID,
} from '@modules/service/constants';

const iconURL = require('@project/public/icons/pwaIcon.png');

const SET_LOGGED_IN = 'SET_LOGGED_IN';
const SET_IS_MULTIPLE = 'SET_IS_MULTIPLE';
const SET_RSI_SESSION = 'SET_RSI_SESSION';
const SET_INVESTMENT_SKYLINE = 'SET_INVESTMENT_SKYLINE';

export default {
	namespaced: true,

	state() {
		return {
			isLoggedIn: false,
			isMultiple: false,
			sessionRSI: null,
			hasInvestmentSkylineAllowed: false,
		};
	},

	mutations: {
		[SET_LOGGED_IN](state, value) {
			state.isLoggedIn = value;
		},

		[SET_IS_MULTIPLE](state, value) {
			state.isMultiple = value;
		},

		[SET_INVESTMENT_SKYLINE](state, value) {
			state.hasInvestmentSkylineAllowed = value;
		},

		[SET_RSI_SESSION](state, value) {
			state.sessionRSI = value;
		},
	},

	actions: {
		/**
		 * LogIn an user with the username and password.
		 *
		 * @param store
		 * @param {Object} payload
		 * @param {String} payload.rememberToken Token
		 * @param {String} payload.username Username
		 * @param {String} payload.password Password
		 */
		async login(
			{ commit, dispatch, rootState },
			{ rememberToken, username, password, isMyDevice, language }
		) {
			await dispatch('secure/createSession', null, { root: true });
			dispatch('contracts/reset', null, { root: true });
			dispatch('loading/start', null, { root: true });

			return new Promise((resolve, reject) => {
				const url = '/login';
				const method = 'POST';
				const { companyId, isCBNK } = rootState.app;
				let loginMethod;
				let loginValue;

				if (rememberToken && !username) {
					loginMethod = 'rememberToken';
					loginValue = rememberToken;
				} else {
					loginMethod = 'documentId';
					loginValue = username;
				}

				const credentials = {
					[loginMethod]: loginValue,
					password,
					companyId,
					channel: 'WEB',
					deviceId: rootState.device.id,
					isMyDevice,
					language,
				};

				const reasons = {
					BAD_CREDENTIALS: 1 << 0,
					BAD_PASSWORD: 1 << 1,
					BAD_USER: 1 << 2,
				};

				dispatch(
					'service/request',
					{
						service: {
							request: {
								url,
								method,
							},
						},
						payload: credentials,
					},
					{ root: true }
				)
					.then(async ({ data }) => {
						if (data.requirePwdChange) {
							const prefix = isCBNK ? 'cbnk-' : '';
							const MPasswordChange = await import(
								/* webpackChunkName: "chunk-m-password-change" */ `@modals/m-${prefix}password-change`
							);
							const pwdChangeResponse = await dispatch(
								'modal/open',
								{ component: MPasswordChange },
								{ root: true }
							);

							/* istanbul ignore else */
							if (pwdChangeResponse !== true) {
								throw pwdChangeResponse;
							}
						}

						/* istanbul ignore if */
						if (isCBNK) {
							await dispatch(
								'session/setVertical',
								{
									vertical: data?.vertical,
									collective: data?.collective,
								},
								{ root: true }
							);
						}

						await dispatch('getContracts', {
							data: { username: data?.username },
						});

						resolve({
							...data,
							[loginMethod]: loginValue,
						});
					})
					.catch((error) => {
						dispatch('loading/end', null, { root: true });

						const reason = { ...reasons };
						const { data = {} } = error?.response ?? {};

						reason.status = reasons.BAD_PASSWORD;

						if (data.errorCode === 'CHANGE_USER') {
							reason.status = reasons.BAD_USER;
						}

						if (data.errorCode === REMEMBER_TOKEN_INVALID) {
							reason.status = reasons.BAD_USER | reasons.BAD_CREDENTIALS;
						}

						if (
							data.errorCode === USER_INVALID_CRED ||
							data.errorCode === USER_NOT_FOUND ||
							data.errorCode === USER_WILL_BE_TEMP_BLOCKED ||
							data.errorCode === USER_WAS_TEMP_BLOCKED ||
							data.errorCode === USER_WILL_BE_PERMANENTLY_BLOCKED
						) {
							reason.status |= reasons.BAD_CREDENTIALS;
						}

						commit(SET_LOGGED_IN, false);
						reject(reason);
					});
			});
		},

		loginByToken({ commit, dispatch, rootState }, { session }) {
			if (rootState.app.isHybridSky) {
				return dispatch('secure/createHybridSession', null, { root: true })
					.then((data) => {
						commit(SET_LOGGED_IN, true);

						return dispatch('device/getDeviceData', null, {
							root: true,
						})
							.then(({ theme }) => ({ ...data, theme }))
							.catch(() => Promise.resolve({ ...data, theme: '' }));
					})
					.catch(() => Promise.reject());
			}

			const method = 'POST';
			const url = '/webview-login';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
					payload: {
						deviceId: rootState.device?.id,
						tokenwebview: session,
					},
				},
				{ root: true }
			)
				.then(() => commit(SET_LOGGED_IN, true))
				.catch((err) => Promise.reject(err));
		},

		loginAnonymous({ dispatch, rootState }) {
			const url = '/login';
			const method = 'POST';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
					payload: {
						companyId: rootState.app.companyId,
						deviceId: rootState.device.id,
						channel: 'WEB',
					},
				},
				{ root: true }
			);
		},

		async logout({ rootState, commit, dispatch }) {
			const { isHybrid, isHybridSky, isEmbedded } = rootState.app;

			if (isEmbedded) {
				const userUUID = rootState.secure.uuid;

				if (window.parent && userUUID) {
					window.parent.postMessage(
						{
							name: 'close-session',
							userUUID,
						},
						'*'
					);
				}
			}

			if (isHybridSky) {
				window.dispatchEvent(new Event('native-exit-app'));
			}

			if (isHybrid) {
				window.postMessage({ name: 'logout' }, '*');
				window.postMessage({ name: 'bridge-exit-app' }, '*');
			}

			await dispatch('modal/closeAll', null, { root: true });
			await dispatch('user/clearCache', null, { root: true });
			await dispatch('notification/closeAll', null, { root: true });
			await dispatch('secure/removeSession', null, { root: true });
			await dispatch('communications/postLoginDisplayed', false, {
				root: true,
			});
			await dispatch(
				'session/setVertical',
				{ vertical: 0, collective: 0 },
				{ root: true }
			);

			commit(SET_LOGGED_IN, false);

			/* istanbul ignore else */
			if (!rootState.session.rememberToken && !isEmbedded) {
				await dispatch('session/loadUserSession', null, { root: true });
			}

			dispatch('loading/end', null, { root: true });
		},

		async activeLogout({ dispatch, rootState }) {
			const { isHybrid, isHybridSky, isEmbedded } = rootState.app;
			const userConfirmation = await dispatch('modal/open', MLogout, {
				root: true,
			});

			/* istanbul ignore else */
			if (userConfirmation) {
				/* istanbul ignore else */
				if (!isEmbedded && !isHybrid) {
					await dispatch('session/deleteSession', null, { root: true });
				}

				/* istanbul ignore next */
				if (
					navigator.credentials &&
					navigator.credentials.preventSilentAccess &&
					!isHybridSky &&
					!isEmbedded
				) {
					navigator.credentials.preventSilentAccess();
				}

				await dispatch('logout');
			}
		},

		async passiveLogout({ dispatch, rootState }) {
			const isCBNK = rootState.app?.isCBNK;
			const prefix = isCBNK ? 'cbnk-' : '';
			const component = await import(
				/* webpackChunkName: "chunk-m-errors" */ `@modals/m-${prefix}expired-session`
			);
			const openModal = () =>
				dispatch('modal/open', component, { root: true });

			if (!rootState.app?.isHybridSky) {
				await dispatch('logout');
				await openModal();
			} else {
				await openModal();
				await dispatch('logout');
			}
		},

		/* istanbul ignore next */
		storeCredentials({ rootState }, { username, password }) {
			if (rootState.device.isPWA && window.PasswordCredential) {
				const credential = new window.PasswordCredential({
					id: username,
					name: username,
					password,
					iconURL: new URL(iconURL, window.location.href),
				});
				navigator.credentials.store(credential);
			}
		},

		async getContracts(
			{ commit, dispatch, rootState },
			{ data, source, origin }
		) {
			dispatch('loading/start', null, { root: true });

			const hasInvestmentSkylineAllowed = await dispatch(
				'hasInvestmentSkylineAllowed'
			);
			const response = await dispatch('contracts/get', null, { root: true });
			const username = rootState.session?.userName || data?.username;
			const userUUID = rootState.secure?.uuid;
			const { contracts = [] } = response;
			const isMultiple = contracts.length > 1;
			let [contract] = contracts;
			const userId = data?.userId;
			const errorData = {
				name: 'error',
				userUUID,
				userId,
			};

			if (rootState.app.isHybridSky) {
				window.dispatchEvent(new Event('native-hide-loading'));
			}

			if (!contract) {
				source?.postMessage(
					{
						...errorData,
						text: 'DASHBOARD.NO_CONTRACTS.ERROR',
					},
					origin
				);
				return Promise.reject(response);
			}

			if (contract && source && origin) {
				source.postMessage(
					{
						name: 'show-frame',
						userUUID,
						userId,
					},
					origin
				);
			}

			/* istanbul ignore else */
			if (
				isMultiple ||
				(contracts.length === 1 && contract?.status === 'BLOCKED')
			) {
				const MContracts = await import(
					/* webpackChunkName: "chunk-m-contracts" */ '@modals/m-contracts'
				);

				contract = await dispatch(
					'modal/open',
					{
						component: MContracts,
						props: {
							contracts: response,
							username,
							modal: true,
						},
					},
					{ root: true }
				);
			}

			const connectedContract = await dispatch('contracts/set', contract, {
				root: true,
			});

			/* istanbul ignore else */
			if (!connectedContract?.id) {
				source?.postMessage(
					{
						...errorData,
						text: 'DASHBOARD.SET_CONTRACTS.ERROR',
					},
					origin
				);

				return Promise.reject(connectedContract);
			}

			commit(SET_INVESTMENT_SKYLINE, hasInvestmentSkylineAllowed);
			commit(SET_IS_MULTIPLE, isMultiple);
			commit(SET_LOGGED_IN, true);

			if (rootState.app.isHybridSky) {
				return Promise.resolve(username);
			}
		},

		/**
		 * Allow access to an agent.
		 */
		authorizeAccess({ dispatch, rootState }, { data, source, origin }) {
			dispatch('loading/start', null, { root: true });

			const url = '/assisted-channels/impersonations';
			const method = 'POST';
			const { userId, vertical, collective } = data;
			const userUUID = rootState.secure.uuid;
			const isCBNK = rootState.app.isCBNK;

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
					payload: { userId },
				},
				{ root: true }
			)
				.then(async () => {
					/* istanbul ignore if */
					if (isCBNK) {
						await dispatch(
							'session/setVertical',
							{
								vertical,
								collective,
							},
							{ root: true }
						);
					}

					return dispatch('getContracts', {
						data,
						source,
						origin,
					});
				})
				.catch(() =>
					source.postMessage(
						{
							name: 'error',
							userUUID,
							userId,
							text: 'DASHBOARD.SOMETHING_WRONG',
						},
						origin
					)
				);
		},

		/**
		 * Create an user session uuid and
		 * associate it with the agent uuid session
		 */
		async createUserSession(
			{ dispatch, rootState },
			{ data, source, origin }
		) {
			await dispatch('secure/createSession', null, { root: true });
			await dispatch(
				'session/setUserSession',
				{ userName: data?.username },
				{ root: true }
			);

			const url = '/associate-uuids';
			const method = 'POST';
			const { agentUUID, userId } = data;
			const userUUID = rootState.secure.uuid;

			return dispatch(
				'service/request',
				{
					service: {
						headers: {
							'Content-Type': 'application/json',
							'uuid': agentUUID,
							'childuuid': userUUID,
						},
						request: {
							url,
							method,
						},
					},
				},
				{ root: true }
			)
				.then(() =>
					source.postMessage(
						{
							name: 'session-is-ready',
							userUUID,
							userId,
						},
						origin
					)
				)
				.catch(() =>
					source.postMessage(
						{
							name: 'error',
							userUUID,
							userId,
							text: 'DASHBOARD.SOMETHING_WRONG',
						},
						origin
					)
				);
		},

		hasInvestmentSkylineAllowed({ dispatch }) {
			const method = 'GET';
			const url = '/products/sheetModeAllowList';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
				},
				{ root: true }
			)
				.then(({ data: { active } }) => active)
				.catch(() => false);
		},

		getRSISession({ commit, dispatch }) {
			const method = 'GET';
			const url = '/products/sso/rsi';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
				},
				{ root: true }
			)
				.then(({ data }) => commit(SET_RSI_SESSION, data))
				.catch(() => commit(SET_RSI_SESSION, null));
		},

		refresh({ dispatch }, { skipLogout, updateRSISession }) {
			const url = '/keep-alive';
			const method = 'GET';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
				},
				{ root: true }
			)
				.then(() => {
					/* istanbul ignore else */
					if (updateRSISession) {
						return dispatch('getRSISession');
					}
				})
				.catch(() => {
					/* istanbul ignore else */
					if (!skipLogout) {
						return dispatch('passiveLogout');
					}
				});
		},
	},
};

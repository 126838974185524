export default {
	namespaced: true,

	actions: {
		getPreferences({ dispatch }) {
			const url = '/preferences/notificationRestrictions';
			const method = 'GET';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
				},
				{ root: true }
			).then(({ data }) => data);
		},

		updatePreferences(
			{ dispatch },
			{ notificationRestrictionStartTime, notificationRestrictionEndTime }
		) {
			const url = '/preferences/notificationRestrictions';
			const method = 'PATCH';
			const payload = {
				notificationRestrictionStartTime,
				notificationRestrictionEndTime,
			};

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
					payload,
				},
				{ root: true }
			);
		},

		deletePreferences({ dispatch }) {
			const url = '/preferences/notificationRestrictions';
			const method = 'DELETE';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
				},
				{ root: true }
			);
		},
	},
};

import SessionCache from '@plugins/cache/index';

const cache = new SessionCache('apple-pay');

export default {
	namespaced: true,

	actions: {
		getApplePayAvailability({ dispatch }, productNumber) {
			const url = '/xpay/active';
			const method = 'GET';
			const cacheKey = `${productNumber}/isApplePayActive`;

			if (cache.has(cacheKey)) {
				return cache.get(cacheKey);
			}

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
				},
				{ root: true }
			)
				.then(({ data }) => {
					cache.set(cacheKey, { isApplePayActive: data?.active });

					return cache.get(cacheKey);
				})
				.catch((err) => Promise.reject(err));
		},

		getCardEligibility({ dispatch }, { productId, productNumber }) {
			const url = `/xpay/active/${productId}`;
			const method = 'GET';
			const cacheKey = `${productNumber}/isCardEligible`;

			if (cache.has(cacheKey)) {
				return cache.get(cacheKey);
			}

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
				},
				{ root: true }
			)
				.then(({ data }) => {
					cache.set(cacheKey, {
						isCardEligible: data?.active,
						cardHolderName: data?.cardholderName,
					});

					return cache.get(cacheKey);
				})
				.catch((err) => Promise.reject(err));
		},

		getCardState(store, cardId) {
			const cacheKey = `${cardId}/isCardAdded`;

			if (cache.has(cacheKey)) {
				return cache.get(cacheKey);
			}

			return new Promise((resolve, reject) => {
				const channel = new MessageChannel();
				const requestTimer = setTimeout(() => {
					reject(new Error('TIMEOUT'));
				}, 10000);

				channel.port1.onmessage = ({ data }) => {
					clearTimeout(requestTimer);

					const isSuccess = data?.status === 200;
					const isCardAdded = data?.isCardAdded;

					if (isSuccess) {
						cache.set(cacheKey, { isCardAdded });
						resolve(data);
					} else {
						reject(data);
					}
				};

				setTimeout(() => {
					window.postMessage(
						{
							name: 'native-get-wallet-status',
							detail: { cardId },
						},
						'*',
						[channel.port2]
					);
				}, 400);
			});
		},

		startCardProvisioning(store, { productId, cardId, cardHolderName }) {
			const cacheKey = `${cardId}/isCardAdded`;

			return new Promise((resolve, reject) => {
				const channel = new MessageChannel();

				channel.port1.onmessage = ({ data }) => {
					const isSuccess = data?.status === 200;

					if (isSuccess) {
						cache.set(cacheKey, { isCardAdded: true });
						resolve(data);
					} else {
						reject(data);
					}
				};

				setTimeout(() => {
					window.postMessage(
						{
							name: 'native-start-card-provisioning',
							detail: {
								productId,
								cardId,
								cardHolderName,
							},
						},
						'*',
						[channel.port2]
					);
				}, 400);
			});
		},

		/* istanbul ignore next */
		clearCache(store, cardId) {
			const cacheKey = `${cardId}/isCardAdded`;

			if (cache.has(cacheKey)) {
				cache.delete(cacheKey);
			}
		},
	},
};

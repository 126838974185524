export default {
	namespaced: true,

	actions: {
		getLighthouseUrl({ dispatch }, { productId, queryParams }) {
			const method = 'GET';
			const url = `/products/${productId}/lighthouse-hybridization`;

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
					queryParams,
				},
				{ root: true }
			).then(({ data }) => data);
		},
	},
};

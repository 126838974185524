/**
 * Returns a random session device id.
 */
const generateUUID = () => {
	const lut = Array.from({ length: 256 }, (a, i) => {
		return (i < 16 ? '0' : '') + i.toString(16);
	});

	/* eslint-disable no-bitwise */
	const d0 = (Math.random() * 0xffffffff) | 0;
	const d1 = (Math.random() * 0xffffffff) | 0;
	const d2 = (Math.random() * 0xffffffff) | 0;
	const d3 = (Math.random() * 0xffffffff) | 0;

	return (
		// eslint-disable-next-line prefer-template
		lut[d0 & 0xff] +
		lut[(d0 >> 8) & 0xff] +
		lut[(d0 >> 16) & 0xff] +
		lut[(d0 >> 24) & 0xff] +
		'-' +
		lut[d1 & 0xff] +
		lut[(d1 >> 8) & 0xff] +
		'-' +
		lut[((d1 >> 16) & 0x0f) | 0x40] +
		lut[(d1 >> 24) & 0xff] +
		'-' +
		lut[(d2 & 0x3f) | 0x80] +
		lut[(d2 >> 8) & 0xff] +
		'-' +
		lut[(d2 >> 16) & 0xff] +
		lut[(d2 >> 24) & 0xff] +
		lut[d3 & 0xff] +
		lut[(d3 >> 8) & 0xff] +
		lut[(d3 >> 16) & 0xff] +
		lut[(d3 >> 24) & 0xff]
	);
};

const SET_DEVICE_DATA = 'SET_DEVICE_DATA';
const DEVICE_STORAGE_KEY = 'DEVICE_STORAGE_KEY';

export default {
	namespaced: true,

	/**
	 * @typedef state
	 * @property {String} id Unique session device id.
	 * @property {Boolean} isPWA Return true if the current session is from an installed progressive web app.
	 */
	state() {
		let deviceId = localStorage.getItem(DEVICE_STORAGE_KEY);

		if (!deviceId) {
			deviceId = generateUUID();
			localStorage.setItem(DEVICE_STORAGE_KEY, deviceId);
		}

		return {
			id: deviceId,
			isPWA: Boolean(
				matchMedia('(display-mode: standalone)').matches ||
					navigator.standalone
			),
			deviceId: '',
			biometricToken: '',
			deviceToken: '',
			hasBiometry: false,
			useBiometry: false,
			mobileOs: '',
			lastLogin: '',
			biometryType: '',
			theme: '',
			appVersion: '',
			isMyDevice: false,
		};
	},

	mutations: {
		/* istanbul ignore next */
		[SET_DEVICE_DATA](state, value) {
			state.deviceId = value?.deviceId;
			state.biometricToken = value?.biometricToken;
			state.deviceToken = value?.deviceToken;
			state.hasBiometry = value?.hasBiometry;
			state.useBiometry = value?.useBiometry;
			state.mobileOs = value?.mobileOs;
			state.lastLogin = value?.lastLogin;
			state.biometryType = value?.biometryType;
			state.theme = value?.theme;
			state.appVersion = value?.appVersion;
			state.isMyDevice = value?.isMyDevice;
		},
	},

	actions: {
		getDeviceData({ commit }) {
			return new Promise((resolve, reject) => {
				const channel = new MessageChannel();
				const requestTimer = setTimeout(() => {
					reject(new Error('TIMEOUT'));
				}, 10000);

				/* istanbul ignore next */
				channel.port1.onmessage = ({ data }) => {
					clearTimeout(requestTimer);

					commit(SET_DEVICE_DATA, data);

					resolve(data);
				};

				setTimeout(() => {
					window.postMessage({ name: 'native-get-device-data' }, '*', [
						channel.port2,
					]);
				}, 400);
			});
		},
	},
};

const SET_MODAL_STATE = 'SET_MODAL_STATE';

export default {
	namespaced: true,

	state() {
		return {
			enableModal: true,
		};
	},

	mutations: {
		[SET_MODAL_STATE](state, value) {
			state.enableModal = !!value;
		},
	},

	actions: {
		requestOtp() {
			return new Promise((resolve) => {
				const channel = new MessageChannel();

				channel.port1.onmessage = ({ data }) => {
					const isSuccess = data?.status === 200;
					const otp = data?.otp?.toString();

					/* istanbul ignore if */
					if (isSuccess) {
						resolve(otp);
					}
				};

				setTimeout(() => {
					window.postMessage(
						{
							name: 'native-request-otp',
							detail: {},
						},
						'*',
						[channel.port2]
					);
				}, 400);
			});
		},

		handle({ state, dispatch }, { component, props }) {
			if (state.enableModal) {
				return dispatch(
					'modal/open',
					{
						component,
						props,
					},
					{ root: true }
				);
			}

			window.postMessage(
				{
					name: 'bridge-request-otp',
					payload: props,
				},
				'*'
			);

			return new Promise((resolve) => {
				const fn = ({ data }) => {
					/* istanbul ignore else */
					if (data?.name === 'bridge-response-otp') {
						window.removeEventListener('message', fn);
						resolve(data.payload);
					}
				};

				window.addEventListener('message', fn);
			});
		},

		send({ dispatch }, { processId, otpValue }) {
			const url = `/validation/${processId}`;
			const method = 'PATCH';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
					payload: { otpValue: otpValue.toString() },
				},
				{ root: true }
			);
		},

		sendEmailCode({ dispatch }, { processId, otpValue }) {
			const url = `/additionalValidation/${processId}`;
			const method = 'PATCH';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
					payload: { otpValue: otpValue.toString() },
				},
				{ root: true }
			);
		},

		requestCode({ dispatch }, processId) {
			const url = `/validation/${processId}`;
			const method = 'PATCH';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
					payload: { otpValue: 'resend' },
				},
				{ root: true }
			);
		},

		disableModal({ commit }) {
			commit(SET_MODAL_STATE, false);
		},

		enableModal({ commit }) {
			commit(SET_MODAL_STATE, true);
		},
	},
};

import Vue from 'vue';
import VueRouter from 'vue-router';
import { importLocale } from '@locales/setup';
import store from '@local-store';
import routes from '@cbnk/router/routes';
import MChunkError from '@modals/m-chunk-error.vue';
import MNoInternet from '@modals/m-no-internet.vue';
import MLogout from '@modals/m-logout.vue';
import MSomethingWrong from '@modals/m-something-wrong.vue';
import serviceWorkerFlow from '@/serviceWorkerFlow';
import scrollBehavior from './scroll-behavior';

Vue.use(VueRouter);

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	scrollBehavior,
	routes,
});

router.onError(async (error) => {
	const { dispatch } = store;

	if (error.name === 'ChunkLoadError') {
		if (error.type === 'missing') {
			await dispatch('modal/open', MChunkError);
		}

		if (error.type === 'error') {
			dispatch('service/setOffline', true);
		}
	}
});

router.beforeEach(async (to, from, next) => {
	/**
	 * There are two ways to set a route as "public":
	 * 1. add the name to the publicPages sentence
	 * 2. add the prefix "public-" to the view
	 */
	const { params, query, meta } = to;
	const publicPages = ['login', 'sso-rsi-form'];
	const authRequired = !publicPages.includes(to.name);
	const { dispatch, state } = store;
	const { isHybrid, isHybridSky, isEmbedded } = state.app;
	const { offline } = state.service;
	const { isLoggedIn } = state.authn;
	const { lang, vertical } = state.session;
	const isDev = process.env.NODE_ENV === 'development';
	const redirect = {
		name: 'login',
		query: { redirect: to.fullPath },
		replace: true,
	};
	const hasModalInQueue = Boolean(
		store.hasModule('modal') && Object.keys(state.modal.queue).length
	);
	const blockedNavigation = Boolean(from.query?.blocked);

	if (lang && (!isHybrid || !isHybridSky)) {
		await importLocale(lang, vertical);
	}

	// We cancel navigation in offline mode or in blocked navigation
	if (offline || blockedNavigation) {
		return;
	}

	// We close any modal if there is a navigation with a modal opened
	if (hasModalInQueue && !params?.action && !query?.entryPoint) {
		await dispatch('modal/closeAll');

		return next(false);
	}

	// We request the exit and logout after navigate to login on any hybrid version
	if (to?.name === 'login' && (isHybrid || isHybridSky || isEmbedded)) {
		// if a password recovery is requested we continue
		if (query?.action === 'pwd-recovery') {
			return next();
		}

		if (isLoggedIn) {
			await store.dispatch('authn/logout');
		}

		return;
	}

	if (isHybrid && (to.name === 'global' || to.name === 'sso-rsi')) {
		window.postMessage(
			{
				name: 'bridge-exit-app',
				to: 'global',
			},
			'*'
		);

		return next(false);
	}

	/**
	 * We request the exit after navigate to the entry point when:
	 * The user request an active logout
	 * The session gets expired
	 * The user request an active logout by pressing back
	 */
	if ((query?.entryPoint || meta?.entryPoint) && isLoggedIn) {
		if (isHybridSky) {
			const isLogoutConfirmed = await store.dispatch('modal/open', MLogout);

			if (isLogoutConfirmed) {
				window.dispatchEvent(new Event('native-exit-app'));
			}
		}

		if (isHybrid) {
			window.postMessage({ name: 'bridge-exit-app' }, '*');
		}

		return next(false);
	}

	/**
	 * We allow access to private routes when:
	 * 1. It is hybrid mode and there is a query.session or
	 * 2. It is hybrid sky mode and
	 * 3. The login by token returns ok
	 */

	if (authRequired && !isLoggedIn) {
		if (query?.entryPoint) {
			return next();
		}

		// CD-11983: The new version does not do anything with the query.session value
		if ((isHybrid && query?.session) || isHybridSky) {
			if (isHybridSky) {
				window.dispatchEvent(new Event('splashscreen-unload'));
				dispatch('loading/start');
			}

			/**
			 * When is a hybrid version we create an entry point
			 * that allows users close the app when they return to the entry point
			 */
			if (!from?.name) {
				return next({
					name: 'entry',
					query: {
						redirect: to.fullPath,
						entryPoint: true,
					},
				});
			}

			if (from?.name) {
				const sessionData = {};

				return store
					.dispatch('authn/loginByToken', { session: query?.session })
					.then((data) => Object.assign(sessionData, data))
					.then(() =>
						store
							.dispatch('user/getPersonalDetails')
							.catch(() => Promise.resolve({ data: { name: '' } }))
					)
					.then(({ data: { name } }) => {
						if (isHybridSky) {
							store.dispatch('session/setVertical', {
								vertical: sessionData.vertical,
								collective: sessionData.collective,
							});

							return store.dispatch('authn/getContracts', {
								data: { username: name },
							});
						}

						return name;
					})
					.then(async (name) => {
						if (lang) {
							await importLocale(lang, sessionData.vertical);
						}

						store.dispatch('session/setUserSession', { userName: name });
						store.dispatch(
							'session/setAppTheme',
							sessionData?.theme || 'light'
						);
					})
					.then(() => {
						if (query.externalotp) {
							return store.dispatch('otp/disableModal');
						}
					})
					.then(() => {
						// CD-11983: Sometime when the app gets second plane and the user does some action
						// that reloads the app, we have to avoid loosing the previous route.
						if (to.name !== 'global' && from.name === 'entry') {
							window.history.replaceState(
								{},
								'',
								router.resolve({ name: 'global' }).href
							);
						}

						return next();
					})
					.then(() => {
						// We force the removing of the device splash screen on hybrid mode
						if (isHybrid) {
							window.dispatchEvent(new Event('splashscreen-unload'));
						}
					})
					.catch(async (error) => {
						dispatch('loading/end');

						if (error?.message === 'Network Error') {
							await dispatch('modal/open', MNoInternet);
						} else {
							await dispatch('modal/open', MSomethingWrong);
						}

						dispatch('authn/logout');
					});
			}
		}

		// Autologin: used only on mocked servers
		/* istanbul ignore next */
		if (isDev) {
			const session = sessionStorage.getItem('SECURE_SESSION');

			if (session) {
				const { rememberToken, password } = JSON.parse(session);

				if (rememberToken && password) {
					return store
						.dispatch('authn/login', {
							rememberToken,
							password,
						})
						.then(
							({
								username: userName,
								lastLogin,
								vertical,
								collective,
							}) => {
								store.dispatch('session/setVertical', {
									vertical,
									collective,
								});
								store.dispatch('session/setUserSession', {
									userName,
									lastLogin,
								});
							}
						)
						.then(() => next())
						.catch(() => next(redirect));
				}
			}
		}

		return next(redirect);
	}

	next();
});

let once = false;

router.afterEach(() => {
	if (!once) {
		once = true;

		/**
		 * We don't use the "to" param to avoid redundant navigation error
		 * We create a state on the history
		 */
		if (router.currentRoute?.query?.entryPoint) {
			const to = router.resolve(router.currentRoute.query.redirect).route;

			router.push({
				name: to.name,
				params: to.params,
				query: to.query,
			});
		}
		serviceWorkerFlow();
	}
});

export default router;

import SessionCache from '@plugins/cache';
import categorizeProducts from '@modules/products/product-sort';

const cache = new SessionCache('move-money');

const normalizeModel = (model) => {
	const result = {
		origin: model.origin.id,
		beneficiary: {
			account: model.destination.account,
			name: model.destination.name,
			favorite: Boolean(model.destination.favorite),
		},
		amount: model.amount,
		date: model.date,
		reason: model.reason,
		transferMode: model.destination.transferMode,
		favorite: Boolean(model.favorite),
		ordererName: model.origin.originator.alias,
	};

	if (model.notify && model.email) {
		Object.assign(result, {
			emailInfo: model.email,
		});
	}

	if (model.favorite) {
		Object.assign(result, {
			alias: model.alias,
		});
	}

	if (model.chargeBearer) {
		Object.assign(result, {
			chargeBearer: model.chargeBearer,
		});
	}

	if (model.periodicity === 'periodic') {
		Object.assign(result, {
			scheduled: {
				frequency: model.frequency,
				lastExecutionDate: model.maxDate,
			},
		});
	}

	return result;
};

export default {
	namespaced: true,

	actions: {
		getTransfer({ dispatch }, { type, transferId, productId, transferMode }) {
			const cacheKey = `${type}/${transferId}`;

			if (cache.has(cacheKey)) {
				return cache.get(cacheKey);
			}

			/* istanbul ignore else */
			if (type === 'ordered' && productId && transferId) {
				const url = `/move-money/${productId}/transfers/${transferId}`;
				const method = 'GET';
				const queryParams = {};

				/* istanbul ignore else */
				if (transferMode?.id) {
					Object.assign(queryParams, { transferMode: transferMode.id });
				}

				return dispatch(
					'service/request',
					{
						service: {
							request: {
								url,
								method,
							},
						},
						queryParams,
					},
					{ root: true }
				).then(({ data }) => {
					cache.set(cacheKey, data);

					return data;
				});
			}

			return dispatch('getTransfers', {
				type,
				productId,
			}).then(({ data }) => {
				return data.find(({ id }) => id === transferId);
			});
		},

		getTransfers({ dispatch }, { type, productId, paginationKey }) {
			const cacheKey = `${type}/${productId}`;

			/* istanbul ignore else */
			if (cache.has(cacheKey) && !paginationKey) {
				return cache.get(cacheKey);
			}

			const url = {
				ordered: `/move-money/${productId}/transfers?type=issued`,
				scheduled: `/move-money/${productId}/transfers/scheduled`,
				favorite: `/move-money/${productId}/transfers/favorites`,
			}[type];
			const method = 'GET';
			const queryParams = {};

			/* istanbul ignore else */
			if (paginationKey) {
				Object.assign(queryParams, {
					paginationKey,
				});
			}

			return dispatch('getOrigins').then((products) =>
				dispatch(
					'service/request',
					{
						service: {
							request: {
								url,
								method,
							},
						},
						queryParams,
					},
					{ root: true }
				).then(({ data }) => {
					const response = {
						paging: data?.paging,
						data: data?.data?.map((item) => {
							const product = products?.find(
								({ id }) => id === productId
							);

							/* istanbul ignore else */
							if (product) {
								Object.assign(item.orderer.fromAccount, {
									id: product?.id,
								});
							}

							return item;
						}),
					};

					/* istanbul ignore else */
					if (cache.has(cacheKey)) {
						const result = cache.get(cacheKey);
						Object.assign(
							response.data,
							result.data.concat(response.data)
						);
					}

					cache.set(cacheKey, response);

					return response;
				})
			);
		},

		getOrigins({ dispatch }, { type = 'transfer', force } = {}) {
			const cacheKey = `origins/${type}`;

			if (!force && cache.has(cacheKey)) {
				return cache.get(cacheKey);
			}

			const url = '/move-money/origins';
			const method = 'GET';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
					queryParams: { type },
				},
				{ root: true }
			).then(({ data: { data } }) => {
				const products = categorizeProducts(data);
				cache.set(cacheKey, products);

				return products;
			});
		},

		getDestinations({ dispatch }, { type = 'transfer' } = {}) {
			const cacheKey = 'destinations';

			if (cache.has(cacheKey)) {
				return cache.get(cacheKey);
			}

			const url = '/move-money/destinations';
			const method = 'GET';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
					queryParams: { type },
				},
				{ root: true }
			).then(({ data: { data } }) => {
				cache.set(cacheKey, data);
				return data;
			});
		},

		getLimits({ dispatch }) {
			const url = '/move-money/transfers/limits';
			const method = 'GET';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
				},
				{ root: true }
			).then(({ data }) => ({
				ownOperationLimit: data.ownAccounts,
				internalOperationLimit: data.internalAccounts,
				externalOperationLimit: data.externalAccounts,
				internalDailyLimit: data.postedInternalAccounts,
				externalDailyLimit: data.postedExternalAccounts,
			}));
		},

		deleteTransfer({ dispatch }, { type, transferId }) {
			const url = {
				ordered: `/move-money/transfers/${transferId}`,
				scheduled: `/move-money/transfers/scheduled/${transferId}`,
				favorite: `/move-money/favorites/${transferId}`,
			}[type];
			const method = 'DELETE';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
				},
				{ root: true }
			).then(() => cache.clear());
		},

		modifyTransfer({ dispatch }, { transferId, model }) {
			const {
				origin,
				beneficiary,
				amount,
				date,
				scheduled,
				reason,
				transferMode,
			} = normalizeModel(model);
			const url = `/move-money/transfers/scheduled/${transferId}`;
			const method = 'PUT';
			const payload = {
				origin,
				beneficiary,
				amount,
				date,
				scheduled,
				reason,
				transferMode,
			};

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
					payload,
				},
				{ root: true }
			).then((res) => {
				cache.clear();
				return res;
			});
		},

		validateFavorite({ dispatch }, { alias, destination }) {
			const { transferMode } = destination;
			const url = '/move-money/favorites/validate';
			const method = 'GET';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
					queryParams: {
						alias,
						transferMode,
					},
				},
				{ root: true }
			);
		},

		validateXBAN({ dispatch }, { iban, type, bic, origin }) {
			const url = '/move-money/validateBICIBAN';
			const method = 'GET';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
					queryParams: {
						iban,
						type,
						bic,
						origin,
					},
				},
				{ root: true }
			).then(({ data: { data } }) => data);
		},

		simulate({ dispatch }, model) {
			const payload = normalizeModel(model);
			const url = '/move-money/transfers/simulate';
			const method = 'POST';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
					payload,
				},
				{ root: true }
			).then(({ data }) => data);
		},

		transfer({ dispatch }, model) {
			const payload = normalizeModel(model);
			const url = '/move-money/transfers';
			const method = 'POST';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
					payload,
				},
				{ root: true }
			).then((res) => res);
		},

		getReceipt(
			{ dispatch },
			{ productId, transferId, transferMode, reportType = 'pdf', isPeriodic }
		) {
			const url = `/move-money/${productId}/transfers/${transferId}/document`;
			const method = 'GET';

			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
					queryParams: {
						type: 'issued',
						reportType,
						transferMode,
						isPeriodic,
					},
				},
				{ root: true }
			).then(({ data: { content } }) => content);
		},

		returnDebit(
			{ dispatch },
			{
				productId,
				movementCoreId,
				id,
				amount,
				internalCreditorReference,
				externalCreditorReference,
			}
		) {
			let directDebitId = movementCoreId;
			if (!movementCoreId) {
				directDebitId = id;
			}
			const url = `/move-money/${productId}/direct-debits/${directDebitId}`;
			const method = 'PATCH';
			const payload = {
				amount,
				internalCreditorReference,
				externalCreditorReference,
			};
			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
					payload,
				},
				{ root: true }
			).then(({ data }) => data);
		},

		cancelDebit(
			{ dispatch },
			{
				productId,
				movementCoreId,
				id,
				amount,
				internalCreditorReference,
				externalCreditorReference,
				action,
			}
		) {
			let directDebitId = movementCoreId;
			if (!movementCoreId) {
				directDebitId = id;
			}
			const url = `/move-money/${productId}/direct-debits/${directDebitId}`;
			const method = 'PATCH';
			const payload = {
				amount,
				internalCreditorReference,
				externalCreditorReference,
				action,
			};
			return dispatch(
				'service/request',
				{
					service: {
						request: {
							url,
							method,
						},
					},
					payload,
				},
				{ root: true }
			).then(({ data }) => data);
		},
	},
};

const SET_IS_EMBEDDED = 'SET_IS_EMBEDDED';
const MERGE_CONFIG = 'MERGE_CONFIG';
const ua = navigator.userAgent;
let isHybridSky = ua.includes('HybridSky');

if (process.env.VUE_APP_ENDPOINT_MODE === 'mck') {
	isHybridSky = process.env.VUE_APP_HYBRID === 'true';
}

export default {
	namespaced: true,

	state() {
		return {
			isCBNK: true,
			isEmbedded: false,
			isHybrid: ua.includes('Skybrid'),
			isHybridSky,
			isIOS: /iPad|iPhone|iPod/.test(ua),
			companyId: 'BC',
			onboardingUrl:
				'https://onboarding-caminos.grupocaminos.es/?utm_source=bco&utm_medium=haztecliente&utm_campaign=cuenta_transparente_bco&utm_term=CCWN4B',
			telephone: '+34 900 107 411 / +34 913 109 550',
			whatsapp: '+34 628 500 200',
			email: '',
			cookiesPolicyLink:
				'https://www.cbnk.es/aviso-legal#politica-de-cookies',
			bizumSafetyAdvice:
				'https://bizum.es/blog/consejos-de-seguridad-de-bizum/',
			measurementId: '',
			legalIdentity:
				'CBNK Banco de Colectivos, S.A. Reg. Mercantil Madrid, T.23454, F.173, S.8, H.M-81730, inscripción 95, Código B.E. 0234, N.I.F: A28520666',
			legalDisclaimerLink: 'http://www.cbnk.es/aviso-legal',
			liveagent: {},
		};
	},

	mutations: {
		[SET_IS_EMBEDDED](state, value) {
			state.isEmbedded = value;
		},
		[MERGE_CONFIG](state, value) {
			Object.assign(state, value);
		},
	},

	actions: {
		setIsEmbedded({ commit }) {
			commit(SET_IS_EMBEDDED, true);
		},

		mergeConfig({ commit }, { config }) {
			commit(MERGE_CONFIG, config);
		},
	},
};
